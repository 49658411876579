import { gql } from "@apollo/client";

/** Contact listing **/
export const CONTACTS_LIST = gql`
  query contactSearchFilter(
    $team_id: Int
    $company_id: Int
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $filter: [CommonFilter!]
    $name: Boolean!
    $first_name: Boolean!
    $last_name: Boolean!
    $email: Boolean!
    #$company: Boolean!
    $job_title: Boolean!
    $address: Boolean!
    $date_of_birth: Boolean!
    $phone: Boolean!
    $mobile: Boolean!
    $fax: Boolean!
    $source: Boolean!
    $stage: Boolean!
    $last_contacted: Boolean!
    $score: Boolean!
    $website: Boolean!
    $created_by: Boolean!
    $updated_at: Boolean!
    $contact_owner: Boolean!
    $campaigns: Boolean!
    $last_activity_date: Boolean!
    $tags: Boolean!
    $social_handler: Boolean!
    $referer: Boolean!
  ) {
    contactSearchFilter(
      team_id: $team_id
      company_id: $company_id
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      filter: $filter
    ) {
      data {
        id
        name @include(if: $name)
        first_name @include(if: $first_name)
        last_name @include(if: $last_name)
        email @include(if: $email)
        verified_status @include(if: $email) {
          status
        }
        company
        company_id
        #@include(if: $company)
        job_title @include(if: $job_title)
        address @include(if: $address)
        date_of_birth @include(if: $date_of_birth)
        phone @include(if: $phone)
        mobile @include(if: $mobile)
        fax @include(if: $fax)
        source @include(if: $source)
        stage @include(if: $stage)
        last_contacted @include(if: $last_contacted)
        score @include(if: $score)
        previous_score @include(if: $score)
        website @include(if: $website)
        created_by_detail @include(if: $created_by) {
          id
          first_name
          last_name
        }
        contact_owner @include(if: $contact_owner) {
          id
          first_name
          last_name
        }
        campaigns @include(if: $campaigns) {
          id
          name
        }
        last_activity_date @include(if: $last_activity_date)
        tags @include(if: $tags) {
          id
          name
        }
        updated_at @include(if: $updated_at)
        social_handler @include(if: $social_handler)
        image {
          id
          path: url
        }
        custom_attributes {
          id
          name
          value
          type
        }
        referer @include(if: $referer)
        created_at
        updated_at
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;

/** Remove Selected Contacts **/
export const REMOVE_CONTACTS = gql`
  mutation deleteContact(
    $team_id: Int
    $type: String
    $unSelected: [ContactIds!]
    $selected: [ContactIds!]
    $search: String
    $filter: [CommonFilter!]
  ) {
    removeSelectedContacts(
      team_id: $team_id
      type: $type
      unchecked_contact_ids: $unSelected
      checked_contact_ids: $selected
      search: $search
      filter: $filter
    ) {
      status
    }
  }
`;

/** Remove Selected Contacts **/
export const REMOVE_CONTACT = gql`
  mutation removeSingleContact($team_id: Int, $id: Int) {
    removeContact(team_id: $team_id, id: $id) {
      status
    }
  }
`;

/** Add | Create new Contact **/
export const CREATE_CONTACT = gql`
  mutation createContact(
    $team_id: Int
    $first_name: String
    $last_name: String
    $email: String
    $company: String
    $job_title: String
    $address: ContactAddress
    $shipping_address: ContactShippingAddress
    $billing_address: ContactBillingAddress
    $dob: Date
    $phone: String
    $mobile: String
    $fax: String
    $lead_owner: Int
    $source: String
    $stage: String!
    $lastContacted: DateTime
    $social_handler: SocialHandler
    $additional: String
    $applyTags: [ApplyTagInput!]
    $newTags: [NewTags!]
    $website: String
  ) {
    createContact(
      team_id: $team_id
      first_name: $first_name
      last_name: $last_name
      email: $email
      company: $company
      job_title: $job_title
      address: $address
      billing_address: $billing_address
      shipping_address: $shipping_address
      date_of_birth: $dob
      phone: $phone
      mobile: $mobile
      fax: $fax
      lead_owner: $lead_owner
      source: $source
      stage: $stage
      last_contacted: $lastContacted
      social_handler: $social_handler
      additional: $additional
      apply_tags: $applyTags
      new_tags: $newTags
      website: $website
    ) {
      id
      team_id
      contact_id
      first_name
      last_name
      name
      phone
      mobile
      fax
      lead_owner
      email
      company
      company_id
      job_title
      address
      date_of_birth
      source
      stage
      social_handler
      website
      address
      billing_address
      image {
        id
        path: url
      }
      tags {
        id
        name
      }
    }
  }
`;
/** Add | Create new Contact **/
export const UPDATE_CONTACT = gql`
  mutation updateContact(
    $team_id: Int
    $id: ID
    $first_name: String
    $last_name: String
    $company: String
    $job_title: String
    $address: ContactAddress
    $shipping_address: ContactShippingAddress
    $billing_address: ContactBillingAddress
    $date_of_birth: Date
    $phone: String
    $mobile: String
    $fax: String
    $lead_owner: Int
    $source: String
    $stage: String
    $lastContacted: DateTime
    $social_handler: SocialHandler
    $additional: String
    $applyTags: [ApplyTagInput!]
    $newTags: [NewTags!]
    $removeTags: [RemoveTagInput!]
    $website: String
    $email: String
    $unsubscribed: Boolean
    $enable_portal: Boolean
  ) {
    updateContact(
      team_id: $team_id
      id: $id
      email: $email
      first_name: $first_name
      last_name: $last_name
      company: $company
      job_title: $job_title
      address: $address
      billing_address: $billing_address
      shipping_address: $shipping_address
      date_of_birth: $date_of_birth
      phone: $phone
      mobile: $mobile
      fax: $fax
      lead_owner: $lead_owner
      source: $source
      stage: $stage
      last_contacted: $lastContacted
      social_handler: $social_handler
      additional: $additional
      apply_tags: $applyTags
      new_tags: $newTags
      remove_tags: $removeTags
      website: $website
      unsubscribed: $unsubscribed
      enable_portal: $enable_portal
    ) {
      id
      team_id
      # contact_id
      first_name
      last_name
      phone
      mobile
      fax
      lead_owner
      email
      company
      job_title
      address
      date_of_birth
      source
      stage
      social_handler
      website
      unsubscribed
      image {
        id
        path: url
      }
      tags {
        id
        name
      }
      enable_portal
    }
  }
`;
/** Country **/
export const COUNTRY_LIST = gql`
  {
    countryList {
      country {
        id
        name
        phone_code
        code
      }
      state {
        id
        name
        country_id
      }
    }
  }
`;

/** All Contact without pagination **/
export const CONTACT_ALL = gql`
  query getAllContacts($search: String, $team_id: Int) {
    allContacts(search: $search, team_id: $team_id) {
      id
      name
    }
  }
`;
export const GET_CONTACT_ALL = gql`
  query contactSearchFilter(
    $team_id: Int
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $filter: [CommonFilter!]
    $minimal_search: Boolean
  ) {
    contactSearchFilter(
      team_id: $team_id
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      filter: $filter
      minimal_search: $minimal_search
    ) {
      data {
        id
        name
        email
        mobile
        image {
          id
          path: url
        }
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;
/** Contact By ID **/
export const CONTACT_BY_ID = gql`
  query contactDetail($id: ID, $team_id: Int) {
    contactDetail(id: $id, team_id: $team_id) {
      id
      score
      team_id
      # contact_id
      name
      first_name
      last_name
      phone
      mobile
      fax
      lead_owner
      email
      company
      job_title
      address
      shipping_address
      billing_address
      date_of_birth
      source
      stage
      social_handler
      website
      last_contacted
      unsubscribed
      image {
        id
        path: url
      }
      tags {
        id
        name
      }
      contact_owner {
        id
        first_name
        last_name
        name
      }
      campaigns {
        id
        name
      }
      custom_attributes {
        id
        name
        value
      }

      product_interested {
        id
        name
        price
        image
      }
      created_at
    }
  }
`;
/**** Update Contact Image ****/
export const UPLOAD_IMAGE = gql`
  mutation updateImage($id: Int!, $image: Upload, $team_id: Int) {
    updateContactImage(id: $id, image: $image, team_id: $team_id) {
      status
    }
  }
`;

/**** Recent Contact Activity ****/
export const RECENT_ACTIVITY = gql`
  query recentActivity($contactId: Int!, $team_id: Int) {
    getActivities(contact_id: $contactId, team_id: $team_id) {
      # data {
      id
      contact_id
      activity_type
      activity_subtype
      description
      created_at
      updated_at
      # }
      # paginatorInfo {
      #   currentPage
      #   total
      #   lastPage
      # }
    }
  }
`;

/**** Broadcast USe Only ***/

export const BROADCAST_CONTACTS_LIST = gql`
  query contactSearchFilter(
    $team_id: Int
    $first: Int!
    $page: Int
    $search: String
    $field: String!
    $order: SortOrder!
    $filter: [CommonFilter!]
  ) {
    contactSearchFilter(
      team_id: $team_id
      first: $first
      page: $page
      search: $search
      orderBy: [{ column: $field, order: $order }]
      filter: $filter
    ) {
      data {
        id
        name
        email
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;
