import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import BillingWrapper from "../../../Pages/Settings/AccountAndBilling/BillingWrapper";
import XAlertMessage from "../../AlertMessage/XAlertMessage";
import { RETRY_PAYMENT } from "../../../../GraphApi/Inventory";
import { useMutation } from "@apollo/client";
import { currentTeamId, getValidationMessage } from "../../../../Utils/utils";

const RetryPaymentWithCardModal = ({
  showModal,
  setShowModal,
  locale,
  planData,
}) => {
  /** Alert Message State **/
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);

  const [userPaymentRetry] = useMutation(RETRY_PAYMENT);
  /****/
  /**ALERT MESSAGE ***/
  const handleAlertMessage = (type, message, isScrollToTop = true) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
  };

  const retryPayment = () => {
    if (!planData.last_payment_id) {
      alert("No subscription found!");
      return;
    }
    const paymentId = planData.last_payment_id;
    userPaymentRetry({
      variables: {
        id: parseInt(paymentId),
        team_id: currentTeamId,
        type: "subscription",
      },
    })
      .then((result) => {
        if (result.data.userPaymentRetry.status === false) {
          handleAlertMessage(
            "error",
            locale(result.data.userPaymentRetry.message)
          );
        } else {
          handleAlertMessage("success", "Payment charged successfully.");
          setTimeout(() => {
            window.location.href = "/settings/accounts-and-billing";
          }, 3000);
        }
      })
      .catch((error) => {
        let { graphQLErrors } = error;
        handleAlertMessage(
          "error",
          locale("messages:backend." + getValidationMessage(graphQLErrors))
        );
      });
  };
  return (
    <Modal
      className="common modalLg modal"
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header className="bg-primary" closeButton>
        <Modal.Title className="text-white secondary-font text-uppercase modal-title h4">
          Retry payment
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <XAlertMessage
          type={alertType}
          message={alertMessage}
          setAlertShow={setAlertShow}
          alertShow={alertShow}
        />
        <BillingWrapper locale={locale} />
      </Modal.Body>

      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Do it later
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            retryPayment();
          }}
        >
          Retry Payment
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RetryPaymentWithCardModal;
