import React, { useEffect } from 'react';
/* Retrieve Data From Server */
import { useMutation } from '@apollo/client';
import { Modal, Row, Col, ListGroup } from 'react-bootstrap';
import { createMarkup, currentTeamId } from '../../../../Utils/utils';
import { ANNOUNCEMENTS_VIEW } from '../../../../GraphApi/Announcement';

export const AnnouncementPreview = ({
  show,
  handleClose,
  currentCompanySetting,
  announcements,
  type = 'announcement',
}) => {
  const [viewAnnouncement] = useMutation(ANNOUNCEMENTS_VIEW);
  const handleAnnouncement = async () => {
    const announcementsIds = announcements.map((item) => item && item.id);
    if (announcementsIds.length > 0) {
      await viewAnnouncement({
        variables: {
          team_id: currentTeamId,
          announcementsIds: announcementsIds,
        },
      })
        .then((result) => {
          console.log('Result', result.data.viewAnnouncement);
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          console.log('Error', graphQLErrors);
        });
    }
  };

  useEffect(() => {
    if (
      type === 'announcement' &&
      show &&
      announcements &&
      announcements.length > 0
    ) {
      handleAnnouncement();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcements, show]);

  /******/
  return (
    <Modal
      id='announcements'
      className={'common modalLg'}
      show={show}
      backdrop='static'
      keyboard={false}
      centered
      onHide={() => handleClose()}
    >
      <Modal.Header
        className={'bg-primary'}
        closeVariant='white'
        closeButton
      >
        <Modal.Title className={'text-white secondary-font text-uppercase'}>
          Announcements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <ListGroup variant='flush'>
              {announcements &&
                announcements.length > 0 &&
                announcements.map(
                  (item, idx) =>
                    item && (
                      <ListGroup.Item key={idx}>
                        {createMarkup(item.description)}
                      </ListGroup.Item>
                    )
                )}
            </ListGroup>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};
