import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import {
  fspLogo,
  isObjectEmpty,
  currentTeamId,
  getValidationMessage,
} from "../../Utils/utils";
import SignupSlider from "./SignupSlider";
import "../Login/login.scss";
import Session from "../../Utils/Session";
import { useTranslation } from "react-i18next";
import InLineError from "../../Components/Common/AlertMessage/InLineError";

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EmailVerification = ({
  email,
  changeEmailForUnconfirmed,
  fetchUserSettings,
  isUserActive,
  emailStatus = null,
}) => {
  const { t: locale } = useTranslation(["common", "pages"]);
  const [isChangeEmail, setIsChangeEmail] = useState(false);
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [resendCount, setResendCount] = useState(0);
  const [changedEmail, setChangedEmail] = useState("");

  useEffect(() => {
    let count = 0;
    try {
      count = Session.getSessionVar("resend");
      count = isObjectEmpty(count) ? 0 : count;
    } catch (e) {
      console.log(e);
      count = 1;
    }

    setResendCount(parseInt(count));
    // setStatus(
    //   <span className="text-success font-weight-bold">
    //     {locale("Verification email sent successfully to {{newEmail}}", {
    //       newEmail: email,
    //     })}
    //   </span>
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (["valid", "", null].indexOf(emailStatus) === -1) {
      setIsChangeEmail(true);
    }
  }, [emailStatus]);

  const validateEmail = (value) => {
    return String(value).toLowerCase().match(emailRegex);
  };

  const resendEmail = (newEmail = null) => {
    console.log(newEmail);
    if (resendCount >= 3) {
      setStatus(
        <span className="text-danger">{locale("Unable to send email")}</span>
      );
      return false;
    } else if (newEmail && resendCount >= 3 && newEmail === email) {
      setError(locale("Unable to send email"));
      setStatus(
        <span className="text-danger">{locale("Unable to send email")}</span>
      );
      return false;
    } else if (isObjectEmpty(newEmail)) {
      setError(locale("Email required!"));
      return false;
    } else if (!isObjectEmpty(changedEmail)) {
      if (!validateEmail(changedEmail)) {
        setError(locale("Invalid email"));
        return false;
      } else {
        newEmail = changedEmail;
      }
    } else if (!isObjectEmpty(newEmail)) {
      //DO Nothing...
    } else {
      setStatus(<span className="text-danger">{locale("Invalid email")}</span>);
      setError(locale("Invalid email"));
      return false;
    }

    changeEmailForUnconfirmed({
      variables: {
        email: newEmail,
        team_id: currentTeamId,
        onResendEmail: true
      },
      update: () => newEmail !== email && fetchUserSettings,
    })
      .then((results) => {
        if (
          results.data &&
          results.data.changeEmailForUnconfirmed &&
          results.data.changeEmailForUnconfirmed.status &&
          newEmail === email
        ) {
          Session.storeSessionVar("resend", parseInt(resendCount) + 1, 3600);
          setResendCount(parseInt(resendCount) + 1);
          setStatus(
            <span className="text-success font-weight-bold">
              {locale("Verification email sent successfully to {{newEmail}}", {
                newEmail: newEmail,
              })}
            </span>
          );
          setError("");
        } else if (
          results.data &&
          results.data.changeEmailForUnconfirmed &&
          results.data.changeEmailForUnconfirmed.status &&
          newEmail !== email
        ) {
          setStatus(
            <span className="text-success font-weight-bold">
              {locale("Verification email sent successfully to {{newEmail}}", {
                newEmail: newEmail,
              })}
            </span>
          );
          setError("");
        } else {
          setError(locale("messages:backend.something went wrong."));
          setStatus(
            <span className="text-danger">
              {locale("messages:backend.something went wrong.")}
            </span>
          );
        }
      })
      .catch((error) => {
        console.log(error);
        let { graphQLErrors } = error;
        console.log("Error:", graphQLErrors);
        const myError = locale(
          "messages:backend." + getValidationMessage(graphQLErrors)
        );
        setError(myError);
        setStatus(<span className="text-danger">{myError}</span>);
      });
  };

  return (
    <div className="container-fluid px-0">
      <div className="row mx-0">
        <div className="col-md-6 px-0">
          <div className="login-signup-form">
            <div className="form-inner-main-bar">
              <div className="brand-logo-form">
                <img src={fspLogo} alt="" />
                <a
                  className="btn btn-lg btn-primary rounded-pill text-nowrap"
                  href="#"
                >
                  Schedule Demo
                </a>
              </div>
              <div className="form-field-bar">
                {!isChangeEmail && (
                  <div className="form-field-bar-inner ">
                    <h2 className="mt-3 text-dark">VERIFY YOUR EMAIL</h2>
                    <p className="">
                      We've sent a verification email to 
                      <b>{changedEmail ? changedEmail : email}</b>. Click the
                      link to activate your account.
                    </p>
                    <h5 className="mb-4">
                      If you didn't receive the email or entered the wrong
                      address, you can:
                    </h5>
                    <p>{status ? status : " "}</p>
                    <button
                      className="btn btn-lg btn-primary w-100 mb-3"
                      type="button"
                      onClick={() => {
                        if (resendCount < 3) {
                          resendEmail(email);
                        }
                      }}
                      disabled={resendCount >= 3}
                    >
                      Send Verification Email Again
                    </button>

                    <button
                      className="btn btn-lg btn-primary w-100 mb-4"
                      type="button"
                      onClick={() => {
                        setIsChangeEmail(true);
                        setStatus(null);
                      }}
                    >
                      Change Email
                    </button>
                  </div>
                )}

                {isChangeEmail && resendCount < 3 && (
                  <div className="form-field-bar-inner ">
                    <h2 className="mt-3 text-dark">
                      VERIFY YOUR ACCOUNT FOR CONTINUED USE
                    </h2>
                    {["valid", "", null].indexOf(emailStatus) === -1 && (
                      <p className="">
                        We noticed a <b>[Disposable]</b> email was used during
                        signup. To reactivate your account, please provide your
                        business email for verification below.
                      </p>
                    )}
                    <p>{status ? status : " "}</p>
                    <Form>
                      <Row>
                        <Col sm="12">
                          <Form.Group className="form-group">
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              className={error ? "is-invalid" : ""}
                              id={"email"}
                              type="text"
                              value={changedEmail}
                              onChange={(e) => {
                                setChangedEmail(e.target.value);
                              }}
                              placeholder="Enter your email address"
                            />
                            <InLineError
                              type="error"
                              name="email"
                              message={error}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <button
                        className="btn btn-lg btn-primary w-100 my-2"
                        type="button"
                        onClick={() => resendEmail(changedEmail)}
                      >
                        Verify
                      </button>{" "}
                      <button
                        className="btn btn-lg btn-primary w-100 my-2"
                        type="button"
                        onClick={() => {
                          setIsChangeEmail(false);
                          setStatus(null);
                          setError(null);
                        }}
                      >
                        Cancel
                      </button>
                    </Form>

                    <div className="or-divider text-uppercase my-3">
                      <span>OR</span>
                    </div>
                    <div className="text-center mb-4">
                      If you believe this is a mistake and your email is
                      legitimate, contact us at <b>info@fieldservicepro.io</b>{" "}
                      or schedule a call with us. 
                    </div>
                    <button
                      className="btn btn-lg btn-primary w-100 mb-4"
                      type="button"
                    >
                      Schedule Call
                    </button>
                  </div>
                )}

                {/*--- Next Page ---*/}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 px-0">
          <SignupSlider />
        </div>
      </div>
    </div>
  );
};
export default EmailVerification;
