import dayjs from "dayjs";
import { getLanguage, isObjectEmpty } from "./utils";
import { handleTimezoneLocale } from "../i18n";

const utc = require("dayjs/plugin/utc");
const timeZone = require("dayjs/plugin/timezone");
const relativeTime = require("dayjs/plugin/relativeTime");
const isBetween = require("dayjs/plugin/isBetween");
const punycode = require("punycode.js");
dayjs.extend(utc);
dayjs.extend(timeZone);
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
export const defaultTimeFormat = "HH:mm:ss";
export const defaultFormat = "MM-DD-YYYY HH:mm:ss";
export const defaultDateFormat = "YYYY-MM-DD";
export const availableDateFormat = [
  "DD/MM/YYYY",
  "MMM/DD/YYYY",
  "DD-MM-YYYY",
  "MM-DD-YYYY",
  "MMM-DD-YYYY",
  "dddd, MMMM DD YYYY",
  "DD/MM/YYYY h:mm:ss A",
  "MMM/DD/YYYY h:mm:ss A",
  "DD-MM-YYYY h:mm:ss A",
  "MMM-DD-YYYY h:mm:ss A",
  "dddd, MMMM DD YYYY, h:mm:ss A",
];

export const getTimeZOneList = () => {
  const aryIanaTimeZones = Intl.supportedValuesOf("timeZone");
  // aryIanaTimeZones.forEach((timeZone) => {
  //   console.log(timeZone);
  // });
  return aryIanaTimeZones;
};
// Reset timezone
export const setDefaultTimezone = () => {
  dayjs.tz.setDefault();
};
// Set User Timezone
export const getUserTimeZone = (currentCompanySetting) => {
  const language = getLanguage(currentCompanySetting);
  handleTimezoneLocale(language);
  return currentCompanySetting && currentCompanySetting.time_zone
    ? currentCompanySetting.time_zone
    : dayjs.tz.guess();
};

export const showTimeZoneString = (
  timezone = dayjs.tz.guess(),
  format = defaultFormat
) => {
  return dayjs().tz(timezone).format(format);
};
// Create new Date Object
export const newDate = (date) => {
  return dayjs(date);
};
// Convert Date with Format
export const getDateformat = (date = null, format = null) => {
  if (date && format !== null) {
    return dayjs(date, format);
  } else if (date && format === null) {
    return dayjs(date);
  } else if (date === null && format !== null) {
    return newDate().format(format);
  }
};
// Convert Date with Strict Mode
export const getStrictDateformat = (date = null, flag = false) => {
  if (date && flag) {
    return dayjs(date, flag);
  } else if (date) {
    return dayjs(date);
  } else {
    return currentDateObject();
  }
};
// Convert Date with Format
export const getDateformatWise = (date = null, format) => {
  if (date) return dayjs(date).format(format);
  else return currentDateObject().format(format);
};

export const getUnixDate = (dateTime) => {
  dayjs.unix();
};

export const convertTimeToUTC = (time, setting, format24Hour = false) => {
  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const newDateFormat = format24Hour
    ? "YYYY-MM-DD HH:mm:ss"
    : "YYYY-MM-DD HH:mm a";
  const zone = setting.time_zone ? setting.time_zone : dayjs.tz.guess();
  if (!isObjectEmpty(time)) {
    const date = getDateformat(null, "YYYY-MM-DD") + " " + time;
    const tm = dayjs(date).format(dateFormat);
    const m = dayjs.tz(tm, dateFormat, zone);
    return m.utc().format("HH:mm:ss");
    // return dayjs(date).utc().format(dateFormat);
  } else return "";
};

export const convertUTCTimeToUserTime = (
  time,
  setting,
  format24Hour = false
) => {
  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const newDateFormat = format24Hour
    ? "YYYY-MM-DD HH:mm:ss"
    : "YYYY-MM-DD HH:mm a";
  const zone = setting.time_zone ? setting.time_zone : dayjs.tz.guess();
  if (!isObjectEmpty(time)) {
    const date = getDateformat(null, "YYYY-MM-DD") + " " + time;
    const tm = dayjs(date).utc(true);

    const m = dayjs.tz(tm, dateFormat, zone);
    return m.format("HH:mm:ss");
    // return dayjs(date).utc().format(dateFormat);
  } else return "";
};
// Current Date Object
export const currentDateObject = () => {
  return dayjs();
};
//Start Date of Current Month
export const startDateOfCurrentMonth = dayjs().startOf("month");

export const getDuration = (diffValue) => {
  return dayjs.duration(diffValue);
};

export const getDayNumber = (date) => {
  return dayjs(date).day();
};

export const getNextDayByName = (date, dayName) => {
  // Array of day names to get the index
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Get the current day index and the target day index
  const currentDayIndex = dayjs(date).day();
  const targetDayIndex = daysOfWeek.indexOf(dayName);

  // Calculate the difference to the next target day
  let dayDifference = targetDayIndex - currentDayIndex;
  if (dayDifference <= 0) {
    dayDifference += 7; // Ensure we get the next occurrence of the target day
  }

  // Add the difference to the current date
  return dayjs(date).add(dayDifference, "day");
};

// Get the current UTC time
export const getUTCDateTime = (dateTime = null) => {
  if (dateTime) {
    return dayjs(dateTime).utc();
  } else {
    return dayjs.utc();
  }
};

// Format the UTC time as needed
export const formattedUTCDateTime = (dateTime = null, format = null) => {
  if (format) {
    return getUTCDateTime(dateTime).format(format);
  }
  return getUTCDateTime(dateTime);
};

// Convert the Unix timestamp to a dayjs object
export const timeStampToDateDate = (unixTimestamp = null, format = null) => {
  if (unixTimestamp) {
    const dateObj = dayjs.unix(unixTimestamp);
    if (format) {
      return dateObj.format(format);
    }
    return dateObj;
  } else {
    if (format) {
      return dayjs().format(format);
    }
    return dayjs();
  }
};

//Convert Timestamp to Date Time in Desired Timezone and Desired Format.
export const dateInTimezone = (
  timestamp = null,
  timezone = null,
  format = null
) => {
  let dateObj = dayjs();
  if (timestamp) {
    dateObj = timeZone(timestamp);
    if (timezone) {
      dateObj = dateObj.tz(timezone);
      if (format) {
        return dateObj.tz(timezone).format(format);
      }
      return dateObj;
    } else if (format) {
      return dateObj.format(format);
    }
    return dateObj;
  } else {
    if (timezone) {
      dateObj = dateObj.tz(timezone);
      if (format) {
        return dateObj.tz(timezone).format(format);
      }
      return dateObj;
    } else if (format) {
      return dateObj.format(format);
    }
    return dateObj;
  }
};

//Already Used In CurrentSetting.js
export const convertToTimestamp = (dateTime, isUtc = false) => {
  const isoFormats = {
    am: "YYYY-MM-DD HH:mm a",
    pm: "YYYY-MM-DD HH:mm a",
    AM: "YYYY-MM-DD HH:mm A",
    PM: "YYYY-MM-DD HH:mm A",
  };

  const formatKeys = Object.keys(isoFormats);
  let selectedFormat = formatKeys.filter((item) => dateTime.includes(item));
  selectedFormat =
    selectedFormat.length > 0 ? isoFormats[selectedFormat[0]] : defaultFormat;
  if (isUtc) {
    return dayjs(dateTime, selectedFormat).valueOf();
  } else {
    return dayjs(dateTime, selectedFormat).valueOf();
  }
};
