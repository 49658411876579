export const avtarList = {
  note_created: "ri-file-list-2-line",
  document_added: "ri-folder-2-line",
  document_uploaded: "ri-folder-2-line",
  quote_created_updated: "ri-chat-quote-line",
  invoice_created_updated: "ri-todo-line",
  appointment_reminder: "ri-calendar-check-line",
  appointment_create: "ri-calendar-check-line",
  appointment_update: "ri-calendar-check-line",
  deal_created: "ri-price-tag-2-fill",
  deal_assigned: "ri-price-tag-2-fill",
  deal_creation: "ri-price-tag-2-fill",
  task_appointment: "ri-calendar-check-line",
  contact_assigned: "ri-contacts-line",
  contact_lifecycle_stage_change: "ri-organization-chart",
  contact_hot_warm_lead: "ri-meteor-line",
  invoice_paid: "ri-todo-line",
  contact_import: "ri-chat-upload-line",
  deal_import: "ri-price-tag-2-fill",
  email_received: "ri-mail-line",
  form_submission: "ri-checkbox-circle-line",
  contact_visits_website: "ri-global-fill",
  last_week_saved_quotes: "ri-chat-quote-line",
  inventory_consumption: "ri-article-line",
  credit_consumption: "ri-article-line",
  tracking_notification: "ri-map-pin-user-line",
  total_todays_task: "ri-calendar-check-line",
  import_error: "ri-chat-upload-line",
  payment_failed: "ri-bank-card-line",
  message_received: "ri-message-fill",
  whatsapp_message_received: "ri-whatsapp-fill",
  whatsapp_template_status: "ri-whatsapp-fill",
  booking_purchased: "ri-calendar-check-line",
  note_creation: "ri-file-list-2-line",
  document_requested: "ri-folder-2-line",
  agreement: "ri-file-list-2-line",
  estimate: "ri-draft-line",
  estimate_job: "ri-draft-line",
  last_week_saved_estimates: "ri-calendar-check-line",
  booking_payment: "ri-currency-line",
  job_form: "ri-checkbox-circle-line",
  service_request: "ri-file-list-2-line",
  task_appointment: "ri-calendar-check-line",
  service_visit: "ri-home-gear-line",
  default: "",
};

export const avtarListClass = {
  note_created: "avtar-bg-yellow",
  document_added: "avtar-bg-blue",
  document_uploaded: "avtar-bg-blue",
  quote_created_updated: "avtar-bg-green",
  invoice_created_updated: "avtar-bg-purple",
  appointment_reminder: "avtar-bg-light-green",
  appointment_create: "avtar-bg-orange",
  appointment_update: "avtar-bg-orange",
  deal_created: "avtar-bg-yellow",
  deal_assigned: "avtar-bg-red",
  deal_creation: "avtar-bg-yellow",
  task_appointment: "avtar-bg-orange",
  contact_assigned: "avtar-bg-orange",
  contact_lifecycle_stage_change: "avtar-bg-pink",
  contact_hot_warm_lead: "avtar-bg-pink",
  invoice_paid: "avtar-bg-light-blue",
  contact_import: "avtar-bg-light-green",
  deal_import: "avtar-bg-light-green",
  email_received: "avtar-bg-purple",
  form_submission: "avtar-bg-purple",
  contact_visits_website: "avtar-bg-light-blue",
  last_week_saved_quotes: "avtar-bg-green",
  inventory_consumption: "avtar-bg-yellow",
  credit_consumption: "avtar-bg-yellow",
  tracking_notification: "avtar-bg-light-blue",
  total_todays_task: "avtar-bg-light-green",
  import_error: "avtar-bg-red",
  payment_failed: "avtar-bg-red",
  booking_purchased: "avtar-bg-orange",
  note_creation: "avtar-bg-light-green",
  document_requested: "avtar-bg-blue",
  estimate_job: "avtar-bg-blue",
  estimate: "avtar-bg-blue",
  service_visit: "avtar-bg-orange",
  last_week_saved_estimates: "avtar-bg-blue",
  booking_payment: "avtar-bg-red",
  job_form: "avtar-bg-light-green",
  service_request: "avtar-bg-blue",
  task_appointment: "avtar-bg-orange",
  agreement: "avtar-bg-blue",
  default: "",
};
