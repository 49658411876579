import { gql } from "@apollo/client";

/** Equp Plans listing **/
export const FSP_PLAN_LIST = gql`
  query getSystemPlans($team_id: Int) {
    getSystemPlans(team_id: $team_id) {
      id
      plan_id
      name
      description
      trial_period
      billing_cycle
      visibility
      no_of_billing
      status
      default
      monthly_price
      yearly_price
      planLimitations {
        setting_name
        setting_value
      }
    }
  }
`;

/** Subscribed Plan Detail **/
export const FSP_SUBSCRIBED_PLAN = gql`
  query getSubscribedPlan($team_id: Int) {
    getSubscribedPlan(team_id: $team_id) {
      start_at
      end_at
      subscription_id
      subscription_count
      trial_expiry_date
      activation_date
      last_billing_date
      next_billing_date
      cancelled_date
      price
      other_details
      status
      user {
        email
      }
      payment_status
      last_payment_id
      created_at
      updated_at
      plan {
        id
        name
      }
    }
  }
`;
/** Upgrade plan **/
export const FSP_PLAN_UPGRADE = gql`
  mutation upgradeSubscription(
    $team_id: Int
    $plan_id: String
    $billing_type: String
    $coupon: String
    $nameOnCard: String
    $card_number: String
    $month: String
    $year: String
    $cvv: String
    $card_type: String
    $billing_address: BillingAddress
  ) {
    upgradeSubscription(
      team_id: $team_id
      plan_id: $plan_id
      billing_type: $billing_type
      billing_address: $billing_address
      coupon: $coupon
      card_name: $nameOnCard
      card_number: $card_number
      month: $month
      year: $year
      cvv: $cvv
      card_type: $card_type
    ) {
      start_at
      end_at
      plan {
        id
        plan_id
        name
        description
        trial_period
        billing_cycle
        visibility
        no_of_billing
        status
        default
        monthly_price
        yearly_price
        created_at
        updated_at
      }
      subscription_count
      subscription_id
      trial_expiry_date
      activation_date
      last_billing_date
      next_billing_date
      cancelled_date
      price
      other_details
      status
      user {
        email
      }
      created_at
      updated_at
    }
  }
`;
export const FSP_PLAN_DOWNGRADE = gql`
  mutation downgradeSubscription(
    $team_id: Int
    $plan_id: String
    $billing_type: String
    $coupon: String
    $nameOnCard: String
    $card_number: String
    $month: String
    $year: String
    $cvv: String
    $card_type: String
    $billing_address: BillingAddress
  ) {
    downgradeSubscription(
      team_id: $team_id
      plan_id: $plan_id
      billing_type: $billing_type
      billing_address: $billing_address
      coupon: $coupon
      card_name: $nameOnCard
      card_number: $card_number
      month: $month
      year: $year
      cvv: $cvv
      card_type: $card_type
    ) {
      start_at
      end_at
      plan {
        id
        plan_id
        name
        description
        trial_period
        billing_cycle
        visibility
        no_of_billing
        status
        default
        monthly_price
        yearly_price
        created_at
        updated_at
      }
      subscription_count
      subscription_id
      trial_expiry_date
      activation_date
      last_billing_date
      next_billing_date
      cancelled_date
      price
      other_details
      status
      user {
        email
      }
      created_at
      updated_at
    }
  }
`;
export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription(
    $team_id: Int
  ) {
    cancelSubscription(
      team_id: $team_id
    ) {
      status
      message
    }
  }
`;
export const FSP_LAYOUTS = gql`
  query equpLayout($team_id: Int) {
    equpLayout(team_id: $team_id) {
      id
      layout_id
      team_id
      name
      plans
      publish
      layout_items {
        id
        plan_id
        plan {
          id
          name
          price
          yearly_price
          billing_type
          no_of_billings
          trial_period
          fee_name
          fee_value
          fee_charge_on
          equpPlan {
            id
          }
        }
      }
      created_at
      updated_at
    }
  }
`;

export const FSP_WALLET = gql`
  query getWallet($team_id: Int) {
    getWallet(team_id: $team_id) {
      credit
    }
  }
`;

export const EXTEND_PLAN_REQUEST = gql`
  mutation setTrialExtendRequest($team_id: Int, $description: String) {
    setTrialExtendRequest(team_id: $team_id, description: $description) {
      status
    }
  }
`;
