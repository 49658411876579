import { useMutation } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router";
import {
  currentTeamId,
  getValidationMessage,
  isObjectEmpty,
} from "../../../../Utils/utils";
import { TrialPlanChecker } from "../../../../Context/TrialPlanChecker";
import { EXTEND_PLAN_REQUEST } from "../../../../GraphApi/EqupPlans/Plan";
import "./notification.css";
import RetryPaymentWithCardModal from "./RetryPaymentWithCardModal";

const textArea = "^[a-zA-Z0-9-#*&^$+=|~?(){}%&@!\"<>/\\',_.-:;\\s /]*$";

const PaymentFailedNotification = () => {
  const { t: locale } = useTranslation();

  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const [redirectTo, setRedirectTo] = useState(false);
  const [isTrialExtend, setIsTrialExtend] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const [error, setError] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  /** Alert Message State **/
  const [type, setType] = useState("");
  const [message, setMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);

  const { planData, isPlanExpired, fetchSubscription } =
    useContext(TrialPlanChecker);
  const [setTrialExtendRequest] = useMutation(EXTEND_PLAN_REQUEST);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    fetchSubscription();
  }, []);
  useEffect(() => {
    if (isPlanExpired && planData !== null) {
      setShow(true);
    }
  }, [isPlanExpired, planData]);

  const closePopup = () => {
    setShow(false);
  };

  /**Error Message Settings and methods For Formik **/
  const handleAlertMessage = (type, message) => {
    setType(type);
    setMessage(message);
    setAlertShow(true);
  };

  const sendRequest = () => {
    setIsSubmitting(true);

    if (isObjectEmpty(requestMessage)) {
      setError(locale("Message is required."));
      return false;
    } else if (!requestMessage.match(textArea)) {
      setError(locale("Message contain some unknown or hidden character."));
      return false;
    } else {
      setTrialExtendRequest({
        variables: { team_id: currentTeamId, description: requestMessage },
      })
        .then((result) => {
          handleAlertMessage(
            "success",
            locale("Your trial plan extend request sent successfully.")
          );
          setIsSubmitting(false);
          setError(null);
        })
        .catch((error) => {
          let { graphQLErrors } = error;
          setError(
            locale("messages:backend." + getValidationMessage(graphQLErrors))
          );
          setIsSubmitting(false);
        });
    }
  };

  /******/
  if (redirectTo) {
    return (
      <Navigate
        to={"/settings/accounts-and-billing"}
        // state={}
      />
    );
  }
  if (
    planData &&
    planData.payment_status === "failed" &&
    pathname !== "/settings/accounts-and-billing"
  )
    return (
      <React.Fragment>
        <div className="pt-2 pb-3 pb-sm-2 px-4 top-navigation payment-failed-box">
          <Row className="align-items-center">
            <Col>
              <div className="d-flex align-items-center">
                <i className="ri-information-fill me-2"></i>
                <p className="m-0">Your payment has failed. Please retry to continue subscription.</p>
              </div>
            </Col>
            <Col sm="auto" className="pe-0 ps-5 ps-sm-2"></Col>
            <Col sm="auto" className="ps-0 top-nav-close">
              <button
                className="btn btn-primary"
                onClick={() => {
                    setShowModal(true);
                }}
              >
                Retry Payment
              </button>
            </Col>
          </Row>
        </div>
        <RetryPaymentWithCardModal showModal={showModal} setShowModal={setShowModal} locale={locale} planData={planData} />
      </React.Fragment>
    );
  return "";
};

export default PaymentFailedNotification;
