import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { UnverifiedContactsContext } from "../../../../Context/UnverifiedContactContext";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../Context/AuthContext";
import { getSettings } from "../../../../Utils/CurrentSetting";

import { FetchCreditAndInitiateVerification } from "./FetchCreditAndInitiateVerification";
import { hasClient } from "../../../../Utils/utils";
import Session from "../../../../Utils/Session";

const UnverifiedContactsNotification = ({ url, locale, teamId }) => {
  const [manualRemoved, setManualRemoved] = useState(false);
  const [confirmVerify, setConfirmVerify] = useState(false);
  const [credits, setCredits] = useState(null);

  const [currentCompanySetting, setCurrentCompanySetting] = useState(null);
  const { userData } = useContext(AuthContext);
  /******/
  const { unverifiedData, getUnverifiedData } = useContext(
    UnverifiedContactsContext
  );

  useEffect(() => {
    if (
      userData &&
      userData.authData &&
      userData.authData.currentTeam &&
      !hasClient(userData)
    ) {
      getUnverifiedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, userData]);

  useEffect(() => {
    if (userData && userData.authData && userData.authData.currentTeam) {
      if (userData.authData.currentTeam.settings) {
        const companySettings = getSettings(
          userData.authData.currentTeam.settings
        );
        setCurrentCompanySetting(companySettings);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const handleClick = () => {
    setConfirmVerify(true);
  };

  /******/
  if (
    [0, 1, 2, 3].indexOf(unverifiedData.checked) === -1 ||
    manualRemoved ||
    [("contacts-verification", "email-verification-notification")].indexOf(
      url
    ) > -1 ||
    !!Session.getSessionVar("email-verify")
  ) {
    return <React.Fragment></React.Fragment>;
  } else {
    return (
      <React.Fragment>
        <div className="pt-2 pb-3 pb-sm-2 px-4 top-navigation">
          <Row className="align-items-center">
            <Col>
              <div className="d-flex align-items-center">
                <i className="ri-information-fill me-2"></i>
                <p className="m-0">
                  {unverifiedData.checked === 0 &&
                    locale(
                      "messages:notification.We see that you have recently added few contacts, Run an Email verification check to verify the emails."
                    )}
                  {unverifiedData.checked === 1 &&
                    locale(
                      "messages:notification.Your contact emails verification is initiated."
                    )}
                  {unverifiedData.checked === 2 &&
                    locale(
                      "messages:notification.Your Contact email verification is in processing."
                    )}
                  {unverifiedData.checked === 3 &&
                    locale(
                      "messages:notification.You contact email verification is completed."
                    )}
                </p>
              </div>
            </Col>
            <Col sm="auto" className="pe-0 ps-5 ps-sm-2">
              {unverifiedData.checked === 0 && (
                <Button
                  className="btn btn-primary btn-primary btn-sm"
                  onClick={handleClick}
                >
                  {locale("Verify Emails Now")}
                </Button>
              )}
              {unverifiedData.checked === 3 && (
                <Link
                  className="btn btn-primary btn-primary btn-sm"
                  to="/contacts-verification"
                  state={{
                    team_notification_id: unverifiedData.processId,
                    checked: unverifiedData.checked,
                  }}
                >
                  {locale("View Results")}
                </Link>
              )}
            </Col>
            <Col sm="auto" className="ps-0 top-nav-close">
              <Button
                variant="link"
                className="btn-sm top-bar-close px-0"
                onClick={() => {
                  Session.storeSessionVar("email-verify", true);
                  setManualRemoved(true);
                }}
              >
                <i className="ri-close-line ri-xl ms-2"></i>
              </Button>
            </Col>
          </Row>
        </div>

        <FetchCreditAndInitiateVerification
          currentCompanySetting={currentCompanySetting}
          credits={credits}
          setCredits={setCredits}
          confirmVerify={confirmVerify}
          setConfirmVerify={setConfirmVerify}
          unverifiedData={unverifiedData}
          getUnverifiedData={getUnverifiedData}
        />
      </React.Fragment>
    );
  }
};

export default UnverifiedContactsNotification;
