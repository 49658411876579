import { gql } from "@apollo/client";

/** Contact listing **/
export const NOTIFICATIONS = gql`
  query getNotifications($team_id: Int) {
    getNotifications(team_id: $team_id) {
      unread_notification_count
      notifications {
        id
        notification_type
        data {
          title
          description
          more_info
          contact_id
          deal_id
          pipeline_id
          landing_page_id
          task_id
          appointment_id
          agreement_id
          estimate_id
          invoice_id
        }
        read_at
        created_at
        updated_at
      }
    }
  }
`;

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription OnNotificationCreated($team_id: Int) {
    notificationCreated(team_id: $team_id) {
      id
      #notification_type
      #data {
      #  title
      #  description
      #  more_info
      #  contact_id
      #  deal_id
      #  pipeline_id
      #  landing_page_id
      #  quote_id
      #  task_id
      #  appointment_id
      #}
      #read_at
      #created_at
      #updated_at
    }
  }
`;

export const PAGINATED_NOTIFICATIONS = gql`
  query getPaginatedNotifications(
    $team_id: Int
    $first: Int!
    $page: Int
    $search: String
  ) {
    getPaginatedNotifications(
      team_id: $team_id
      first: $first
      page: $page
      search: $search
    ) {
      data {
        id
        notification_type
        #   notifiable_type
        #   notifiable_id
        data {
          title
          description
          more_info
          contact_id
          deal_id
          pipeline_id
          landing_page_id
          task_id
          appointment_id
          invoice_id
        }
        read_at
        created_at
        updated_at
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
    }
  }
`;
/** Delete notification **/
export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($id: String, $team_id: Int) {
    deleteNotification(d: $id, team_id: $team_id) {
      status
    }
  }
`;
/** Mark notification as read **/
export const MARK_READ_NOTIFICATION = gql`
  mutation markReadNotification($team_id: Int, $d: String) {
    markReadNotification(team_id: $team_id, d: $d) {
      status
    }
  }
`;

export const GET_NOTIFICATION_BY_ID = gql`
  query getNotificationById($team_id: Int, $notification_id: String) {
    getNotificationById(team_id: $team_id, notification_id: $notification_id) {
      id
      notification_type
      data {
        title
        description
        more_info
        contact_id
        deal_id
        pipeline_id
        landing_page_id
        task_id
        appointment_id
        invoice_id
      }
      read_at
      created_at
      updated_at
    }
  }
`;
