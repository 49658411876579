import React from "react";
import { EqupRoundLoaderWithOverlay } from "../Components/Common/Loader/RoundLoader";

const RetryFallback = ({ retry }) => {
  return (
    <div>
      {/* <div>Loading failed. Please try again.</div>
      <button onClick={retry}>Retry</button> */}
      <EqupRoundLoaderWithOverlay />
    </div>
  );
};

export default RetryFallback;
