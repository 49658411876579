import React, { useEffect, useRef, useState } from "react";

import "../../../../Pages/Settings/settings.scss";
import { currentTeamId, scrollToTop } from "../../../../Utils/utils";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ADDON_LIST,
  ADD_PAYMENT_METHOD,
  DEFAULT_PAYMENT_METHOD,
  PAYMENT_METHOD_ADDRESS_DETAILS,
} from "../../../../GraphApi/Inventory";

import { Billing } from "./Billings";

export const BillingWrapper = ({ locale }) => {
  const refDrawer = useRef(null);
  /** Alert Message State **/
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertShow, setAlertShow] = useState(false);

  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);
  const [
    getFspAddons,
    { data: addonsData, loading: addonLoading, error: addonError },
  ] = useLazyQuery(ADDON_LIST, { fetchPolicy: "network-only" });

  const [addNewPaymentMethod] = useMutation(ADD_PAYMENT_METHOD);

  const [makeDefaultPaymentMethod] = useMutation(DEFAULT_PAYMENT_METHOD);

  const [
    getPaymentMethodAddressDetails,
    { data: equpAddonsData, loading: billingLoading },
  ] = useLazyQuery(PAYMENT_METHOD_ADDRESS_DETAILS, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (equpAddonsData) {
      setPaymentMethodList(equpAddonsData.getPaymentMethods);
      setAddressList(equpAddonsData.getAddresses);
      equpAddonsData.getPaymentMethods.forEach((element) => {
        if (element.default === true) {
          setHasDefaultPaymentMethod(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equpAddonsData]);

  useEffect(() => {
    if (
      (addressList && addressList.length === 0) ||
      (paymentMethodList && paymentMethodList.length === 0)
    )
      updatePaymentMethodDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAddons = () => {
    getFspAddons({
      variables: { team_id: currentTeamId },
      fetchPolicy: "network-only",
    });
  };

  const updatePaymentMethodDetails = () => {
    getPaymentMethodAddressDetails({
      variables: { team_id: currentTeamId },
      fetchPolicy: "no-cache",
    });
  };

  /**ALERT MESSAGE ***/
  const handleAlertMessage = (type, message, isScrollToTop = true) => {
    setAlertType(type);
    setAlertMessage(message);
    setAlertShow(true);
    if (isScrollToTop) {
      scrollToTop(refDrawer);
    }
  };
  /******/
  return (
    <React.Fragment>
      <div ref={refDrawer}>
        <Billing
          paymentMethodList={{
            paymentMethodList: paymentMethodList,
            loading: addonLoading,
            error: addonError,
          }}
          getAddons={updatePaymentMethodDetails}
          addNewPaymentMethod={addNewPaymentMethod}
          handleAlertMessage={handleAlertMessage}
          addressList={addressList}
          teamId={currentTeamId}
          makeDefaultPaymentMethod={makeDefaultPaymentMethod}
          locale={locale}
        />
      </div>
    </React.Fragment>
  );
};

export default BillingWrapper;
