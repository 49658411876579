import React, { Suspense, useContext, useEffect, useState } from "react";

import { Navigate, useLocation } from "react-router";
import Navigation from "../Layouts/Navigation";
import { AuthContext } from "../Context/AuthContext";
import { hasAffiliate, isObjectEmpty, publicRoutesName } from "../Utils/utils";
import RoundLoaderWithOverlay from "../Components/Common/Loader/RoundLoader";

const PublicRoutes = ({ component: RouteComponent, ...rest }) => {
  const [isFetchingData, setIsFetchingData] = useState(true);
  const { userData } = useContext(AuthContext);
  const { pathname } = useLocation();

  useEffect(() => {
    //const  meta = document.createElement("meta");
    var meta = document.getElementById("metaDescription");

    if (pathname === "/sign-in") {
      document.title = "Field Service Pro Login";
      meta.name = "description";
      meta.content = "Sign in to your Field Service Pro account";
    } else if (pathname === "/signup") {
      document.title = "Sign up with Field Service Pro";
      meta.name = "description";
      meta.content = "Sign up to create your Field Service Pro account";
    } else if (pathname === "/verify-email") {
      document.title = "Verify Email";
      meta.name = "description";
      meta.content = "Verify Email";
    } else {
      document.title = "Field Service Pro";
      meta.name = "description";
      meta.content = "Field Service Pro";
    }
    //document.getElementsByTagName("head")[0].appendChild(meta);
  });

  useEffect(() => {
    if (isFetchingData && !isObjectEmpty(userData)) {
      setTimeout(() => {
        setIsFetchingData(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const executePublicRoute = (rest) => {
    if (
      publicRoutesName.filter(
        (item) => item !== "/signup" && pathname.includes(item)
      ).length > 0
    ) {
      return <RouteComponent />;
    } else if (userData.authenticated) {
      if (hasAffiliate(userData)) {
        return <Navigate to="/dashboard" />;
      } else if (
        publicRoutesName.filter((item) => pathname.includes(item)).length === 0
      ) {
        return (
          <Navigate
            to="/choose-company"
            state={{
              landingUrl:
                rest.location &&
                rest.location.state &&
                rest.location.state.landingUrl
                  ? rest.location.state.landingUrl
                  : "/dashboard",
            }}
          />
        );
      } else if (pathname !== "/signup" || pathname !== "verify-email") {
        return <Navigate to="/dashboard" />;
      } else {
        return <RouteComponent />;
      }
    } else if (!isFetchingData && userData && !userData.authenticated) {
      return <RouteComponent />;
    }
  };

  /*******/
  return (
    <>
      {rest.hasNavigation && <Navigation />}
      <Suspense fallback={<RoundLoaderWithOverlay />}>
        {executePublicRoute(rest)}
      </Suspense>
    </>
  );
};
export default PublicRoutes;
