import { gql } from "@apollo/client";

/** Product listing **/
export const INVENTORY_LIST = gql`
  query getInventory($team_id: Int) {
    getInventory(team_id: $team_id) {
      stats
    }
  }
`;

export const ADDON_LIST = gql`
  query getAddons($team_id: Int) {
    getAddons(team_id: $team_id) {
      data
    }
    getSubscribedAddons(team_id: $team_id) {
      id
      user_id
      name
      addon_value
      start_at
      last_billing_date
      next_billing_date
      cancelled_date
      price
      other_details
      created_at
      updated_at
      user {
        email
      }
    }
    getPaymentMethods(team_id: $team_id) {
      id
      user_id
      name
      card_number
      expiry_date
      default
      card_type
      created_at
      updated_at
      address {
        id
        address_type
        address
        city
        state
        country
        zip_code
        created_at
        updated_at
      }
    }
    getTeamsListing(team_id: $team_id) {
      id
      company_name
      created_by
      status
      created_at
      updated_at
      industry_info
      created_by_user {
        id
        name
      }
    }
    getAddresses(team_id: $team_id) {
      id
      address_type
      address
      city
      state
      country
      zip_code
      created_at
      updated_at
    }
  }
`;

export const ADDON_SUBSCRIBED = gql`
  query getSubscribedAddons($team_id: Int) {
    getSubscribedAddons(team_id: $team_id) {
      id
      user_id
      name
      addon_value
      start_at
      last_billing_date
      next_billing_date
      cancelled_date
      price
      other_details
      created_at
      updated_at
      user {
        email
      }
    }
  }
`;

export const ADDON_BUY = gql`
  mutation buyAddon($team_id: Int, $id: Int) {
    buyAddon(team_id: $team_id, id: $id) {
      status
    }
  }
`;

export const MULTIPLE_ADDON_BUY = gql`
  mutation buyMultipleAddon($team_id: Int, $id: [Int]) {
    buyMultipleAddon(team_id: $team_id, id: $id) {
      status
    }
  }
`;

export const PAYMENT_METHOD_LIST = gql`
  query getPaymentMethods($team_id: Int) {
    getPaymentMethods(team_id: $team_id) {
      id
      user_id
      name
      card_number
      expiry_date
      default
      card_type
      created_at
      updated_at
      address {
        id
        address_type
        address
        city
        state
        country
        zip_code
        created_at
        updated_at
      }
    }
  }
`;

export const ADD_PAYMENT_METHOD = gql`
  mutation addNewPaymentMethod(
    $team_id: Int
    $address_type: String
    $address: String
    $address_id: Int
    $city: String
    $state: String
    $country: String
    $zip_code: String
    $name: String
    $card_number: String
    $expiry_date: String
    $card_type: String
    $cvv: String
  ) {
    addPaymentMethod(
      team_id: $team_id
      address_type: $address_type
      address: $address
      city: $city
      state: $state
      country: $country
      zip_code: $zip_code
      name: $name
      card_number: $card_number
      expiry_date: $expiry_date
      card_type: $card_type
      address_id: $address_id
      cvv: $cvv
    ) {
      id
      user_id
      name
      card_number
      expiry_date
      default
      card_type
      address {
        id
        address_type
        address
        city
        state
        country
        zip_code
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;

export const MAKE_DEFAULT_METHOD = gql`
  mutation makeDefaultPaymentMethod($team_id: Int, $id: Int) {
    makeDefaultPaymentMethod(team_id: $team_id, id: $id) {
      id
      user_id
      name
      card_number
      expiry_date
      default
      card_type
      address {
        id
        address_type
        address
        city
        state
        country
        zip_code
        created_at
        updated_at
      }
      created_at
      updated_at
    }
  }
`;
export const ADD_ACCOUNT = gql`
  mutation addTeam(
    $team_id: Int
    $first_name: String
    $last_name: String
    $company: String
    $website: String
    $industry_info: String
    $recommended_tasks: String
  ) {
    addTeam(
      team_id: $team_id
      input: {
        first_name: $first_name
        last_name: $last_name
        company: $company
        website: $website
        industry_info: $industry_info
        recommended_tasks: $recommended_tasks
      }
    ) {
      id
    }
  }
`;

export const REMOVE_PAYMENT_METHOD = gql`
  mutation deletePaymentMethod($team_id: Int, $id: ID) {
    deletePaymentMethod(team_id: $team_id, id: $id) {
      status
    }
  }
`;

export const ADDRESS_LIST = gql`
  query getAddress($team_id: Int) {
    getAddresses(team_id: $team_id) {
      id
      address_type
      address
      city
      state
      country
      zip_code
      created_at
      updated_at
    }
  }
`;

export const DEFAULT_PAYMENT_METHOD = gql`
  mutation makeDefaultPaymentMethod($team_id: Int, $id: Int) {
    makeDefaultPaymentMethod(team_id: $team_id, id: $id) {
      id
    }
  }
`;

export const PAYMENT_METHOD_ADDRESS_DETAILS = gql`
  query getPaymentMethodAddressDetails($team_id: Int) {
    getPaymentMethods(team_id: $team_id) {
      id
      user_id
      name
      card_number
      expiry_date
      default
      card_type
      created_at
      updated_at
      address {
        id
        address_type
        address
        city
        state
        country
        zip_code
        created_at
        updated_at
      }
    }
    getAddresses(team_id: $team_id) {
      id
      address_type
      address
      city
      state
      country
      zip_code
      created_at
      updated_at
    }
  }
`;

export const USER_INVOICE_LISTING = gql`
  query userInvoiceList($team_id: Int) {
    userInvoiceList(team_id: $team_id) {
      id
      name
      plan
      invoice_amount
      status
      payment_error
      created_at
      type
    }
  }
`;

export const EXPORT_USER_INVOICE = gql`
  mutation exportUserInvoicePDF($team_id: Int, $invoice_id: Int) {
    exportUserInvoicePDF(team_id: $team_id, invoice_id: $invoice_id) {
      url
    }
  }
`;
export const RETRY_PAYMENT = gql`
  mutation userPaymentRetry($team_id: Int, $id: Int, $type: String) {
    userPaymentRetry(team_id: $team_id, id: $id, type: $type) {
      status
      message
    }
  }
`;
