import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ChooseCompany from "../../Pages/SwitchCompany/ChooseCompany";
import { getUserTeams } from "../../../Utils/utils";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../Context/AuthContext";

export const SwitchCompanyPopup = () => {
  const { t: locale } = useTranslation();
  const [showSwitchCompany, setShowSwitchCompany] = useState(false);
  const handleCloseSwitchCompany = () => setShowSwitchCompany(false);
  const handleShowSwitchCompany = () => setShowSwitchCompany(true);
  const { userData } = useContext(AuthContext);

  return (
    <React.Fragment>
      {getUserTeams(userData).length > 1 && (
        <Button
          variant={"link"}
          className="dropdown-item"
          onClick={handleShowSwitchCompany}
          style={{
            justifyContent: "left",
            color: "#566678",
            fontWeight: "500",
            fontSize: "0.975rem",
            borderRadius: "8px",
            padding: "0.5rem 1rem",
            margin: "0.1rem",
          }}
        >
          <i className="ri-building-line me-2 text-primary"></i>{" "}
          <span className="text-capitalize">
            {locale("pages:navigation.Switch Company")}
          </span>
        </Button>
      )}
      <Modal
        className={"common modalLg choose-company-modal"}
        show={showSwitchCompany}
        onHide={handleCloseSwitchCompany}
      >
        <Modal.Header closeVariant="white" closeButton className={"bg-primary"}>
          <Modal.Title className={"text-white secondary-font text-uppercase"}>
            {locale("pages:navigation.Choose Company")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChooseCompany
            mode={"popup"}
            handleClose={handleCloseSwitchCompany}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary btn-w-icon btn-ms-icon"
            onClick={handleCloseSwitchCompany}
          >
            <i className={"ri-close-line"}></i> <span>{locale("cancel")}</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
