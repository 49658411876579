import React from "react";
import { Carousel } from "react-bootstrap";
const SignupSlider = () => {
    return (
        <>
        <Carousel fade className="login-slider d-none d-lg-block">
            <Carousel.Item>
              <div className="carousel-item-wrap">
                <img src={require('../../Assets/Images/login/scheduling-dispatch-img.png')} alt="" />         
                <h3>Smart Scheduling & Dispatch</h3>
                <p>Effortless scheduling meets intelligent dispatch. Get your team on the road faster with our powerful tools.</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item-wrap">
                <img src={require('../../Assets/Images/login/recurring-jobs.png')} alt="" /> 
                <h3>Recurring Jobs</h3>
                <p>Never miss a beat. Schedule jobs to repeat automatically, ensuring on-time service for your customers.</p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item-wrap">
                <img src={require('../../Assets/Images/login/online-booking.png')} alt="" />   
                <h3>Online Booking</h3>
                <p>Simplify scheduling. Let your customers book appointments online, saving you time and resources. </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item-wrap">
                <img src={require('../../Assets/Images/login/estimates-job.png')} alt="" />   
                <h3>Estimates and Job Templates </h3>
                <p>Win customer trust. Generate professional estimates instantly with templates and impress your clients with transparency. </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item-wrap">
                <img src={require('../../Assets/Images/login/agreements-maintanance.png')} alt="" />   
                <h3>Agreements for Maintanance Contracts </h3>
                <p>Streamline approvals. Manage service contracts efficiently and get customers on board faster. </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item-wrap">
                <img src={require('../../Assets/Images/login/all-communication.png')} alt="" />   
                <h3>All-in-One Communication </h3>
                <p>Reach Everyone. Choose the best way to connect - email, text, or WhatsApp - for every customer. </p>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="carousel-item-wrap">
                <img src={require('../../Assets/Images/login/marketing-campaigns.png')} alt="" />   
                <h3>Marketing Campaigns </h3>
                <p>Generate more leads and engage existing customers by sending targeted campaigns across Email, WhatsApp & SMS. </p>
              </div>
            </Carousel.Item>
          </Carousel>
        </>
    );
}
export default SignupSlider;