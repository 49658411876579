import React from "react";
import { ProgressBar, Spinner } from "react-bootstrap";
import "./loader.css";
const transparentLoader = {
  background: "transparent",
};

export const RoundLoader = ({ externalClass = "" }) => {
  return (
    <div
      className={"spinner-wrap " + externalClass}
      style={{ zIndex: "9999999" }}
    >
      <div className={"spinner-inner"} style={transparentLoader}>
        <Spinner animation="border" variant="warning" />
      </div>
    </div>
  );
};

/** EQUP COLOR LOADER **/
const RoundLoaderWithOverlay = ({ externalClass = "" }) => {
  return (
    <div
      className={"spinner-wrap " + externalClass}
      style={{ zIndex: "9999999" }}
    >
      <img
        src={require("../../../Assets/Images/fspLoader-1.gif")}
        height="60"
        width="60"
      />
    </div>
  );
};

export const EqupRoundLoaderWithOverlay = ({ externalClass = "" }) => {
  return (
    <div
      className={"spinner-wrap " + externalClass}
      style={{ zIndex: "9999999" }}
    >
      <img
        src={require("../../../Assets/Images/fspLoader-1.gif")}
        height="60"
        width="60"
      />
      {/* <div className={"spinner-inner"} style={transparentLoader}>
        <div className="equp-loader"></div>
      </div> */}
    </div>
  );
};

export const EqupProgressBarWithOverlay = ({
  externalClass = "",
  now = 0,
  message = "",
}) => {
  return (
    <div
      className={"spinner-wrap " + externalClass}
      style={{ zIndex: "9999999" }}
    >
      <div className={"equp-progress"}>
        <ProgressBar animated now={now} label={`${parseInt(now)}%`} />
        <p className="text-center small text-primary mt-2">{message}</p>
      </div>
    </div>
  );
};

export default RoundLoaderWithOverlay;
