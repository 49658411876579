import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router";
import { getAllowedPages, settingMenu } from "../../../Utils/utils";
import { ModulePermission } from "../../../Context/ModulePermission";
import { AuthContext } from "../../../Context/AuthContext";

const urlSchema = {
  crm: ["crm", "contact-details", "tasks"],
  companies: ["company-management", "company-details"],
  dashboard: ["dashboard", "add-dashboard", "edit-dashboard"],
  tracking_script: ["website-tracking-details"],
  owners: ["owners"],
  pipelines: ["pipeline", "pipelines"],
  campaigns: [
    "campaign",
    "campaign",
    "campaign-builder",
    "campaign-analytics",
    "choose-prebuilt-campaigns",
    "emails",
    "email-builder",
    "campaign-compare",
  ],
  workflows: ["workflow", "workflow-builder", "choose-prebuilt-workflows"],
  catalog: ["services", "ecommerce", "subscription", "add-plan", "add-layout"],
  profile: ["my-profile"],
  settings: ["settings", "email_configuration"],
  social_media: [
    "social-media",
    "social-media/add-account",
    "social-media/social-post",
    "social-media/monitoring",
  ],
  landing_pages: ["landing_pages", "landing-page-builder"],
  emails: ["emails", "email-builder"],
  bookings: ["bookings", "booking-preference", "create-booking-preference"],
  //scoring: ["scoring-management"],
  notifications: ["notifications"],
  role_permissions: ["add-role"],
};

export const ValidatePermission = ({
  type = "url",
  path = null,
  urlHasPermission,
  setUrlHasPermission = () => {},
  hasDefault = false,
  industry,
}) => {
  const { pathname } = useLocation();
  const { t: locale } = useTranslation();
  const [show, setShow] = useState(false);
  const [redirectTo, setRedirectTo] = useState(false);
  const { userData } = useContext(AuthContext);
  const { allowedPages, setAllowedPages, onPage, setOnPage, isShowAll } =
    useContext(ModulePermission);

  useEffect(() => {
    /*** GET & SET PAGE PERMISSION ***/
    if (userData) {
      const allPagesAllowed = getAllowedPages(userData);
      setAllowedPages(allPagesAllowed);
    }
    /******/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    if (allowedPages && allowedPages.length > 0 && industry !== null) {
      checkPathPermission(allowedPages, path);
    } else if (allowedPages && allowedPages.length === 0 && industry !== null) {
      checkPathPermission(allowedPages, path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowedPages, path, industry]);

  useEffect(() => {
    if (onPage) {
      setShow(true);
    }
  }, [onPage]);

  const closePopup = () => {
    setShow(false);
    setOnPage(false);
  };

  const findModuleFromUrl = (url) => {
    let moduleName = null;
    Object.keys(urlSchema).forEach((item) => {
      urlSchema[item].forEach((name) => {
        if (name === url || url.includes(name)) {
          moduleName = item;
        }
      });
    });

    if (
      moduleName === "catalog" &&
      ["add-plan", "add-layout"].indexOf(url) === -1
    ) {
      moduleName = null;
    }

    return moduleName === "settings" ? "basic_settings" : moduleName;
  };

  const checkPathPermission = (allowedPages, path) => {
    setUrlHasPermission(true);
    setShow(false);
  };

  const checkPathPermissionBkp = (allowedPages, path) => {
    if (hasDefault) {
      setUrlHasPermission(true);
      setShow(false);
    } else if (
      allowedPages.indexOf(findModuleFromUrl(path.replace("/", ""))) > -1
    ) {
      setUrlHasPermission(true);
      setShow(false);
    } else if (
      path === "/email-builder" &&
      allowedPages.indexOf("campaigns") > -1
    ) {
      setUrlHasPermission(true);
      setShow(false);
    } else if (path.includes("settings")) {
      const settingPath = path.replace("/settings/", "");
      const menu = settingMenu.filter((item) => item.path === settingPath);
      const hasForm =
        allowedPages.indexOf("landing_pages") > -1 ? ["forms"] : [];
      const hasApps = ["app-management", "plugins", "job_forms"];

      if (
        menu.length > 0 &&
        [...hasForm, ...hasApps, ...allowedPages].indexOf(menu[0].name) > -1
      ) {
        setUrlHasPermission(true);
        setShow(false);
      } else if (
        ["affiliates", "announcements"].indexOf(settingPath) > -1 &&
        userData.authData.is_super_admin
      ) {
        setUrlHasPermission(true);
        setShow(false);
      } else if (
        allowedPages.indexOf("calling_sms") > -1 &&
        ["whatsapp-management"].indexOf(settingPath) > -1
      ) {
        setUrlHasPermission(true);
        setShow(false);
      } else if (
        ["emails", "email-configuration"].indexOf(settingPath) > -1 &&
        allowedPages.indexOf("campaigns") > -1
      ) {
        setUrlHasPermission(true);
        setShow(false);
      } else if (
        settingPath === "add-role" &&
        allowedPages.indexOf("role_permissions") > -1
      ) {
        setUrlHasPermission(true);
        setShow(false);
      } else if (
        settingPath === "tasks" &&
        allowedPages.indexOf("crm_detail") > -1
      ) {
        setUrlHasPermission(true);
        setShow(false);
      } else if (
        settingPath === "form-management" &&
        allowedPages.indexOf("crm_detail") > -1
      ) {
        setUrlHasPermission(true);
        setShow(false);
      } else if (isShowAll && menu.length > 0) {
        setUrlHasPermission(true);
        setShow(false);
      } else {
        setUrlHasPermission(false);
        onPage && setShow(true);
      }
    } else {
      setUrlHasPermission(false);
      onPage && setShow(true);
    }
  };

  /******/
  if (redirectTo && pathname !== "/settings/accounts-and-billing") {
    return (
      <Navigate
        to="/settings/accounts-and-billing"
        state={{
          redirectPageParams: {
            activeTab: "accounts-and-billing",
            subTab: "plan",
          },
        }}
      />
    );
  } else if (redirectTo && pathname === "/settings/accounts-and-billing") {
    setRedirectTo(false);
  }

  return (
    <Modal
      show={show}
      onHide={closePopup}
      style={{ zIndex: 9999 }}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{locale("Permission Denied")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{locale("You do not have permission.")}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closePopup}>
          {locale("Do Later")}
        </Button>

        <Button variant="primary" onClick={() => setRedirectTo(true)}>
          {locale("Upgrade Plan")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
